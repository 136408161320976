import { useLocale } from '@components/Contexts/LocaleContext';
import { be } from 'models/conent';
import { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import AutoSlide from '@components/AutoSlide/AutoSlide';

const logoPath = '/images/new-homepage/customer-images/';

/*
แนะนำให้จัดขนาดโลโก้ใน Figma ก่อน:
1. นำโลโก้เข้า Figma และสร้าง Frame ขนาด 150×150 px
2. ปรับขนาดรูปให้อยู่กึ่งกลางของ Frame เพื่อให้โลโก้ทั้งหมดมีขนาดเท่ากัน ฉันใช้ขนาดกว้าง 150 px หรือปรับตามความเหมาะสม
3. ใช้ Plugin "WebP Exporter" เพื่อ Export ไฟล์เป็น .webp ช่วยลดขนาดไฟล์โดยไม่เสียคุณภาพ (Export แบบ 2x จะได้รูปชัดกว่า)
*/
const customers: LogoImage[] = [
  {
    name: 'scg',
    src: 'scg.webp',
    alt: 'Siam Cement Group',
    width: 150,
    height: 150,
  },
  {
    name: 'bls-bcap',
    src: 'bls_bcap.webp',
    alt: 'BLS BCAP',
    width: 150,
    height: 150,
  },
  {
    name: 'power-buy',
    src: 'power_buy.webp',
    alt: 'Power Buy',
    width: 150,
    height: 150,
  },
  {
    name: 'komatsu',
    src: 'komatsu.webp',
    alt: 'Komatsu',
    width: 150,
    height: 150,
  },
  {
    name: 'siam-motors-group',
    src: 'siam_motors_group.webp',
    alt: 'Siam Motors Group',
    width: 150,
    height: 150,
  },
  {
    name: 'aira-alful',
    src: 'aira_alful.webp',
    alt: 'Aira Alful',
    width: 150,
    height: 150,
  },
  {
    name: 'hitachi',
    src: 'hitachi.webp',
    alt: 'Hitachi',
    width: 150,
    height: 150,
  },
  {
    name: 'siam-music-yamaha',
    src: 'siam_music_yamaha.webp',
    alt: 'Siam Music Yamaha',
    width: 150,
    height: 150,
  },
  {
    name: 'siam-at-siam',
    src: 'siam_siam.webp',
    alt: 'Siam@Siam',
    width: 150,
    height: 150,
  },
  {
    name: 'bon-cafe',
    src: 'bon_cafe.webp',
    alt: 'Bon Cafe',
    width: 150,
    height: 150,
  },
  {
    name: 'owndays',
    src: 'owndays.webp',
    alt: 'Owndays',
    width: 150,
    height: 150,
  },
];

const CustomerSection = () => {
  const { lang } = useLocale();
  const ref = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const calculateContainerWidth = useCallback(() => {
    if (ref.current) {
      const children = Array.from(ref.current.children);
      const totalWidth = children.reduce((sum, child) => {
        const childWidth = child.getBoundingClientRect().width;
        return sum + childWidth;
      }, 0);
      setContainerWidth(totalWidth);
    }
  }, []);

  useEffect(() => {
    calculateContainerWidth();
    window.addEventListener('resize', calculateContainerWidth);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', calculateContainerWidth);
    };
  }, [calculateContainerWidth]);

  const title = {
    en: "CODIUM is Thailand's #1 Digital Native Company as a Service, proudly delivering top-tier solutions to over 500 organizations.",
    th: 'CODIUM คือผู้ให้บริการ Digital Native Company as a Service อันดับ 1 ของประเทศไทย ที่ภาคภูมิใจให้บริการให้กับกว่า 500 องค์กรชั้นนำ',
  };

  return (
    <section aria-labelledby="customers">
      <p className="Container text-p2Semibold text-center md:text-left text-lightBlue mb-10">
        {title[lang]}
      </p>

      <div className="Container flex justify-center items-center">
        <AutoSlide
          containerWidth={containerWidth}
          duration={40}
          pauseOnHover={false}
        >
          <div ref={ref} className="flex items-center gap-6">
            {[...customers, ...customers, ...customers].map((item, index) => (
              <div
                key={index}
                className={`w-[80px] h-[80px] lg:w-[100px] lg:h-[100px] bg-[#FFFFFF0A] rounded-2xl flex items-center my-4`}
              >
                <Image
                  src={`${logoPath}${item.src}`}
                  alt={item.alt}
                  width={item.width}
                  height={item.height}
                  objectFit={'contain'}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </AutoSlide>
      </div>
    </section>
  );
};

export default CustomerSection;

interface LogoImage {
  name: string;
  src: string;
  alt: string;
  width?: number;
  height?: number;
}
