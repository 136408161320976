import { useLocale } from '@components/Contexts/LocaleContext';

const DigitalTransformationSection = () => {
  const { lang } = useLocale();

  const descriptions = {
    th: (
      <>
        เมื่อ “ข้อมูล” ของคุณอยู่ในรูปแบบ Digital <br className="md:hidden" />
        ทุกปัญหาจะหมดไป
      </>
    ),
    en: (
      <>
        When data goes digital,{' '}
        <br className="md:hidden" />
        complexity becomes clarity.
      </>
    ),
  };

  return (
    <section
      className="relative overflow-hidden md:h-[250px] lg:h-[550px]"
      aria-labelledby="digital-transformation"
    >
      {/* Background Video */}
      <video
        autoPlay
        muted
        loop
        playsInline
        preload="auto"
        disablePictureInPicture
        className="w-full"
      >
        <source src="/videos/homepage-bg-video-1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div
        className="absolute inset-0 w-full h-full flex justify-center items-center"
        style={{
          background:
            'linear-gradient(180deg, #0A1027 0%, rgba(10, 16, 39, 0) 38.73%)',
        }}
      >
        <div className="Container flex-column gap-5 md:gap-8 text-center">
          <h2 className="text-h4Bold md:text-dSmallBold lg:text-dLargeBold">
            Data should go digital!
          </h2>
          <p className="text-p2Semibold md:text-h5Semibold">
            {descriptions[lang]}
          </p>
        </div>
      </div>
    </section>
  );
};

export default DigitalTransformationSection;
