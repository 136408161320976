import BusinessOutcomes from './BusinessOutcomes';
import CustomerSection from './CustomerSection';
import ESGService from './ESGService';
import SoftwareSolutionService from './SoftwareSolutionService';

const ServiceSection = () => {
  return (
    <div className="relative" aria-labelledby="services">
      <div className="absolute h-full">
        <video
          autoPlay
          muted
          loop
          playsInline
          aria-label="Digital transformation background video"
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        >
          <source src="/videos/homepage-bg-video-2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="absolute inset-0 w-full h-full flex justify-center items-center bg-[#000000] opacity-40"></div>
      <div
        className="absolute inset-0 w-full h-full flex justify-center items-center"
        style={{
          background:
            'linear-gradient(180deg, #0A1027 0%, rgba(10, 16, 39, 0) 38.73%)',
        }}
      >
      </div>
      <div className="relative flex-column gap-12 xxl:gap-24 px-2 py-12">
        <ESGService />
        <SoftwareSolutionService />
        <CustomerSection />

        <BusinessOutcomes />
      </div>
    </div>
  );
};

export default ServiceSection;
