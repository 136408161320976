import CarouselNewDesign from '@components/CarouselNewDesign/CarouselNewDesign';
import { Title } from '@components/Common';
import { useLocale } from '@components/Contexts/LocaleContext';
import { useTailwindBreakpoint } from '@components/Contexts/TailwindBreakpointContext';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { ESGCardServiceProps, ESGTagProps } from './Service.model';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const Tag: React.FC<ESGTagProps> = ({ text }) => {
  return (
    <span className="bg-[#171F34] text-p2Medium lg:text-p1Medium text-lightBlue px-4 py-2.5 rounded-full">
      {text}
    </span>
  );
};

const CardService: React.FC<ESGCardServiceProps> = ({
  icon,
  title,
  detail,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className="h-full flex-column gap-5 rounded-[20px] bg-[#FFFFFF0A] p-6"
    >
      <div>
        <motion.div
          style={{ width: 'fit-content' }}
          initial={{ opacity: 0, scale: 1 }}
          animate={
            inView
              ? {
                  opacity: 1,
                  scale: [1, 1.2, 1],
                  transition: { type: 'spring', duration: 1 },
                }
              : { opacity: 0, scale: 1 }
          }
        >
          <Image src={icon} alt={title} width={60} height={60} />
        </motion.div>
        <h3 className="text-h5MobileSemiBold md:text-h4MobileSemiBold first-letter:text-lightBlue">
          {title}
        </h3>
      </div>
      <p className="text-p2Regular">{detail}</p>
    </div>
  );
};

const ESGService = () => {
  const { lang } = useLocale();
  const { screenWidth } = useTailwindBreakpoint();
  const [initWidth, setInitWidth] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setInitWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (screenWidth) {
      setInitWidth(null);
    }
  }, [screenWidth]);

  const title = {
    en: 'CODIUM ESG',
    th: 'CODIUM ESG',
  };

  const description = {
    en: (
      <>
        We offer comprehensive services, including consultation, solution
        development, <br className="hidden md:block" />
        and tailored customization to meet the unique needs of your
        organization.
      </>
    ),
    th: (
      <>
        CODIUM ให้บริการแบบครบวงจร ตั้งแต่การให้คำปรึกษา การพัฒนาโซลูชัน
        และการปรับแต่งให้เหมาะสม <br className="hidden md:block" />
        เพื่อรองรับความต้องการเฉพาะขององค์กรคุณ
      </>
    ),
  };

  const services = [
    'Consulting',
    'Digitization Solutions',
    'Custom Development',
  ];

  const serviceInformation = [
    {
      src: '/images/new-homepage/money-bag.png',
      title: 'Earnings & Expenses',
      detail: {
        en: (
          <>
            At CODIUM, we empower organizations with{' '}
            <strong>good governance and transparency.</strong> Our solutions
            enhance <strong>stability, sustainability, and security</strong>,
            ensuring ethical and effective operations that benefit both
            businesses and society.
          </>
        ),
        th: (
          <>
            ที่ CODIUM เราช่วยให้องค์กรดำเนินงานด้วย{' '}
            <strong>ธรรมาภิบาลและความโปร่งใส</strong> โซลูชันของเรายกระดับ{' '}
            <strong>เสถียรภาพ ความยั่งยืน และความปลอดภัย</strong>{' '}
            เพื่อให้มั่นใจว่าการดำเนินธุรกิจเป็นไปอย่างมีจริยธรรมและมีประสิทธิภาพ
            สร้างผลประโยชน์ให้ทั้งองค์กรและสังคม
          </>
        ),
      },
    },
    {
      src: '/icons/people-team.svg',
      title: 'Stakeholders',
      detail: {
        en: (
          <>
            A reliable system fosters{' '}
            <strong>
              transparency, reduces errors, and eliminates repetitive tasks
            </strong>
            , streamlining communication and boosting trust. The result? A
            seamless experience that truly supports and delights every
            stakeholder, keeping them genuinely{' '}
            <strong>&apos;Happy.&apos;</strong>
          </>
        ),
        th: (
          <>
            ระบบที่มีประสิทธิภาพช่วยส่งเสริม{' '}
            <strong>ความโปร่งใส ลดข้อผิดพลาด และขจัดงานซ้ำซ้อน</strong>{' '}
            ทำให้การสื่อสารราบรื่นและเพิ่มความไว้วางใจ
            ผลลัพธ์คือประสบการณ์ที่ไร้รอยต่อ
            ช่วยให้ทุกฝ่ายที่เกี่ยวข้องได้รับการสนับสนุนและพึงพอใจอย่างแท้จริง
            ทำให้ทุกคน <strong>&apos;Happy&apos;</strong> อย่างแท้จริง
          </>
        ),
      },
    },
    {
      src: '/icons/classical-building.svg',
      title: 'Governance',
      detail: {
        en: (
          <>
            At CODIUM, we help organizations achieve{' '}
            <strong>good governance and transparency</strong> with solutions
            that enhance stability and sustainability. Beyond profitability, we
            foster <strong>ethical, secure, and transparent</strong> systems for
            long-term impact.
          </>
        ),
        th: (
          <>
            ที่ CODIUM เราช่วยให้องค์กรบรรลุ{' '}
            <strong>ธรรมาภิบาลและความโปร่งใส</strong>{' '}
            ผ่านโซลูชันที่เสริมสร้างเสถียรภาพและความยั่งยืน
            ไม่เพียงแค่ผลกำไรเท่านั้น เรามุ่งเน้นการพัฒนา{' '}
            <strong>ระบบที่มีจริยธรรม ปลอดภัย และโปร่งใส</strong>{' '}
            เพื่อสร้างผลกระทบเชิงบวกในระยะยาว
          </>
        ),
      },
    },
  ];

  return (
    <section aria-labelledby="esg-service">
      <div className="Container flex-column gap-12 lg:gap-24">
        <div className="flex-column gap-10">
          <div className="flex-column gap-5">
            <Title>{title[lang]}</Title>

            <p className="p-text text-center">{description[lang]}</p>

            <div className="flex flex-wrap md:justify-center items-center gap-2.5">
              {services.map((service) => (
                <Tag key={service} text={service} />
              ))}
            </div>
          </div>

          <div
            className="lg:hidden"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onTouchStart={() => setIsHovered(true)}
            onTouchEnd={() => setIsHovered(false)}
          >
            <CarouselNewDesign
              percent_width={100}
              position_x={initWidth >= 768 || screenWidth >= 768 ? 50 : 100}
              interval={5000}
              autoSlide={true}
              isHovered={isHovered}
              currentSlide={() => {}}
            >
              {serviceInformation.map((service) => (
                <div
                  key={service.title}
                  className="md:px-2.5 md:max-w-[50%]"
                  style={{ flex: '1 0 100%' }}
                >
                  <CardService
                    icon={service.src}
                    title={service.title}
                    detail={service.detail[lang]}
                  ></CardService>
                </div>
              ))}
            </CarouselNewDesign>
          </div>

          <div className="hidden lg:flex gap-8">
            {serviceInformation.map((service) => (
              <div className="flex-1" key={service.title}>
                <CardService
                  icon={service.src}
                  title={service.title}
                  detail={service.detail[lang]}
                ></CardService>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ESGService;
