import { motion } from 'framer-motion';
import { useState } from 'react';

interface AutoSlideProps {
  children: React.ReactNode;
  containerWidth?: number;
  duration?: number;
  pauseOnHover?: boolean;
}

const AutoSlide: React.FC<AutoSlideProps> = ({
  children,
  containerWidth = 0,
  duration = 30,
  pauseOnHover = true,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleTouchStart = () => {
    if (pauseOnHover) {
      setIsHovered(true); // หยุดการสไลด์เมื่อแตะ
    }
  };

  const handleTouchEnd = () => {
    setIsHovered(false); // เริ่มการสไลด์ใหม่เมื่อไม่แตะ
  };

  return (
    <div
      className="overflow-hidden"
      onTouchStart={handleTouchStart} // เมื่อแตะเริ่มหยุด
      onTouchEnd={handleTouchEnd} // เมื่อเลิกแตะเริ่มสไลด์ใหม่
    >
      <motion.div
        className="flex"
        initial={{ x: '0%' }}
        animate={{ x: isHovered && pauseOnHover ? 'inherit' : '-100%' }} // ถ้าแตะหยุดการสไลด์
        transition={{
          duration: duration, // speed
          ease: 'linear',
          repeat: Infinity, // Loop infinitely
        }}
        style={{ width: `${containerWidth / 2}px` }}
        whileHover={ pauseOnHover ? { x: 'inherit' } : {}} // Keep the current position when hovering
      >
        {children}
      </motion.div>
    </div>
  );
};

export default AutoSlide;
