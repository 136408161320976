import { Footer } from '@components/Footer';
import { Navbar } from '@components/Navbar';
import { FC, ReactNode } from 'react';
import NoSSR from 'react-no-ssr';

interface Props {
  children: ReactNode | ReactNode[];
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <div className="h-full w-full overflow-hidden mx-auto">
      <NoSSR>
        <Navbar />
      </NoSSR>
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
