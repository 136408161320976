import { Button } from '@components/Common';
import Image from 'next/image';
import { useLocale } from '@components/Contexts/LocaleContext';
import { motion } from 'framer-motion';
import AutoSlide from '@components/AutoSlide/AutoSlide';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  click: any;
}

const hidden = { opacity: 0, y: 20 };
const visible = { opacity: 1, y: 0 };
const transition = { duration: 0.7 };

const backgrounds = [
  {
    src: '/images/new-homepage/bg-homepage-sm.webp',
    width: 375,
    height: 667,
    objectPosition: 'center',
    className: 'md:hidden',
  },
  {
    src: '/images/new-homepage/bg-homepage-md.webp',
    width: 744,
    height: 618,
    objectPosition: 'right center',
    className: 'hidden md:block lg:hidden',
  },
  {
    src: '/images/new-homepage/bg-homepage-lg.webp',
    width: 1440,
    height: 712,
    objectPosition: 'right center',
    className: 'hidden lg:block',
  },
];

const partners = [
  {
    name: 'power-buy',
    src: '/images/new-homepage/partners/power_buy.webp',
    alt: 'Power Buy',
    width: 120,
    height: 75,
  },
  {
    name: 'yamaha',
    src: '/images/new-homepage/partners/yamaha.webp',
    alt: 'Siam Music Yamaha',
    width: 120,
    height: 75,
  },
  {
    name: 'hitachi',
    src: '/images/new-homepage/partners/hitachi.webp',
    alt: 'Hitachi',
    width: 120,
    height: 75,
  },
  {
    name: 'scg',
    src: '/images/new-homepage/partners/scg.webp',
    alt: 'SCG',
    width: 120,
    height: 75,
  },
  {
    name: 'komatsu',
    src: '/images/new-homepage/partners/komatsu.webp',
    alt: 'Komatsu',
    width: 120,
    height: 75,
  },
];

const HeroSection: FC<Props> = ({ click }) => {
  const { lang } = useLocale();
  const ref = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const calculateContainerWidth = useCallback(() => {
    if (ref.current) {
      const children = Array.from(ref.current.children);
      const totalWidth = children.reduce((sum, child) => {
        const childWidth = child.getBoundingClientRect().width;
        return sum + childWidth;
      }, 0);
      setContainerWidth(totalWidth);
    }
  }, []);

  useEffect(() => {
    calculateContainerWidth();
    window.addEventListener('resize', calculateContainerWidth);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', calculateContainerWidth);
    };
  }, [calculateContainerWidth]);

  const titleContent = {
    en: (
      <>
        Simplifying Your Digital <br className="hidden lg:block" />
        Transformation Journey. <br className="hidden lg:block" />
        Customer First, Digital First.
      </>
    ),
    th: (
      <>
        ให้ CODIUM เป็นแรงขับเคลื่อนธุรกิจของคุณในยุคดิจิทัล{' '}
        <br className="hidden lg:block" />
        ด้วยโซลูชันที่ช่วยทำให้การจัดการ Work Flow Digitalization{' '}
        <br className="hidden lg:block" />
        และข้อมูลเป็นเรื่องง่ายสำหรับบริษัทคุณ
      </>
    ),
  };

  const descriptions = {
    th: (
      <>
        CODIUM คือผู้นำด้าน Digital Native Company as a Service ในประเทศไทย{' '}
        <br className="hidden lg:block" />
        ผู้เชี่ยวชาญด้าน Work Flow Digitalization และข้อมูลในรูปแบบดิจิทัล{' '}
        <br className="hidden lg:block" />
        เปลี่ยนแปลงกระบวนการทำงานของคุณด้วยโซลูชันดิจิทัลแบบครบวงจร{' '}
        <br className="hidden lg:block" />
        ที่ออกแบบมาเพื่อช่วยให้คุณทำงานได้เร็วขึ้น ฉลาดขึ้น และปลอดภัยยิ่งขึ้น
      </>
    ),
    en: (
      <>
        CODIUM is Thailand&apos;s leading provider of Digital Native Company as
        a Service, <br className="hidden lg:block" />
        specializing in digital document and data management.{' '}
        <br className="hidden lg:block" />
        Transform your workflows with our all-in-one digital solutions,{' '}
        <br className="hidden lg:block" />
        designed to help you work faster, smarter, and more securely.
      </>
    ),
  };

  const partnerText = {
    th: <>CODIUM ให้บริการบริษัทชั้นนำในประเทศไทยกว่า 500 บริษัท</>,
    en: <>CODIUM serves the top companies in Thailand</>,
  };

  return (
    <section className="relative" aria-labelledby="hero-section" role="banner">
      {/* Background Image */}
      {backgrounds.map(({ src, width, height, objectPosition, className }) => (
        <div
          key={src}
          className={`${className} min-h-[667px] h-[calc(100vh-3rem)] md:h-[calc(100vh-3.5rem)]`}
        >
          <Image
            src={src}
            alt="Hero section background"
            layout="fill"
            objectFit="cover"
            objectPosition={objectPosition}
            loading="lazy"
          />
        </div>
      ))}

      <div className="absolute top-0 w-full h-full flex flex-col justify-between items-center xl:items-start py-12 md:py-[60px] xxl:py-[75px]">
        <div className="Container flex-column items-center lg:items-start gap-5 md:gap-8 px-2">
          {/* Title */}
          {lang === 'en' ? (
            <motion.h1
              className="gradient-head-large text-center text-h5Semibold md:text-h2Bold md:leading-[3.5rem] lg:text-dSmallBold lg:text-left px-2"
              initial={hidden}
              animate={visible}
              transition={transition}
            >
              {titleContent[lang]}
            </motion.h1>
          ) : (
            <motion.h1
              className="gradient-head-large text-center text-h6Semibold leading-8 md:text-h4Semibold md:leading-10 lg:text-left px-2"
              initial={hidden}
              animate={visible}
              transition={transition}
            >
              {titleContent[lang]}
            </motion.h1>
          )}

          {/* Description */}
          <motion.p
            className="text-p2Regular lg:text-p1Regular text-center lg:text-left px-2"
            initial={hidden}
            animate={visible}
            transition={{ ...transition, delay: 0.25 }}
          >
            {descriptions[lang]}
          </motion.p>

          {/* Button */}
          <div className="px-2">
            <Button
                id="contact-us-esignature"
                icon="/icons/speech.svg"
                text={lang === 'en' ? 'CONTACT US' : 'ติดต่อเรา'}
                className="text-sm border border-white"
                onClick={click}
              />
          </div>
        </div>

        {/* Partners */}
        <div className="Container flex-column gap-2.5 pb-6 lg:pb-0 mx-auto w-full">
          <p className="text-center text-p2Medium">{partnerText[lang]}</p>

          <div className="md:hidden overflow-hidden pb-3">
            <AutoSlide containerWidth={containerWidth} duration={50}>
              <div ref={ref} className="flex items-center">
                {[...partners, ...partners].map(
                  ({ name, src, alt, width, height }, index) => (
                    <div key={`${name}-${index}`} className="flex items-center">
                      <div className="min-w-[100px] h-full flex justify-center items-center">
                        <Image
                          src={src}
                          alt={alt}
                          width={width}
                          height={height}
                          objectFit="contain"
                          loading="lazy"
                        />
                      </div>
                      <div className="w-2 h-6 mx-2 flex justify-center items-center">
                        <div className="w-0.5 h-full bg-white"></div>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </AutoSlide>
          </div>

          <div className="hidden md:flex justify-center items-center px-3">
            {partners.map(({ name, src, alt, width, height }, index) => (
              <div key={name} className="flex items-center">
                <Image
                  src={src}
                  alt={alt}
                  width={width}
                  height={height}
                  objectFit="scale-down"
                  loading="lazy"
                />
                {index < partners.length - 1 && (
                  <div className="w-0.5 h-5 bg-white mx-4" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
