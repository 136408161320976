import FormContact from '@components/shared/FormContact';
import DigitalTransformationSection from './DigitalTransformationSection/DigitalTransformationSection';
import HeroSection from './HeroSection/HeroSection';
import ServiceSection from './ServiceSection/ServiceSection';
import { useState } from 'react';

const Home = () => {
  const [scroll, setScroll] = useState(false);

  const scrollToContact = () => {
    setScroll(!scroll);
  };

  return (
    <main className="pt-[3rem] md:pt-[3.5rem] bg-[#0A1026] text-white">
      <HeroSection click={scrollToContact} />
      <DigitalTransformationSection />
      <ServiceSection />
      <FormContact product="HOMEPAGE" event="homepageFormSubmit" scroll={scroll} />
    </main>
  );
};

export default Home;
