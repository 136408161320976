import { motion, useInView } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';

interface CarouselNewDesignProps {
  children: React.ReactNode[];
  percent_width: number;
  position_x?: number;
  interval?: number;
  autoSlide?: boolean;
  isHovered?: boolean;
  height?: number;
  currentSlide?: (index: number) => void;
}

const CarouselNewDesign: React.FC<CarouselNewDesignProps> = ({
  children,
  percent_width = 100,
  position_x = 100,
  interval = 3000,
  autoSlide = false,
  isHovered = false,
  height,
  currentSlide,
}) => {
  const [current, setCurrent] = useState(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once: true });

  const next = useCallback(() => {
    setCurrent((curr) => (curr === children.length - 1 ? 0 : curr + 1));
  }, [children.length]);

  useEffect(() => {
    let slideInterval;

    if (isInView && autoSlide && !isHovered) {
      slideInterval = setInterval(next, interval);
    }

    return () => {
      if (slideInterval) {
        clearInterval(slideInterval);
      }
    };
  }, [autoSlide, next, isHovered, isInView, interval]);

  useEffect(() => {
    if (autoSlide) {
      currentSlide(current);
    }
  }, [current]);

  return (
    <div
      ref={ref}
      className="overflow-x-scroll scrollbar-none scroll-smooth relative"
      style={{ width: `${percent_width}%` }}
    >
      <div
        className="overflow-hidden rounded-[20px] w-full"
        style={height ? { height: `${height}px` } : {}}
      >
        <motion.div
          className="flex"
          animate={{ x: isInView ? `-${current * position_x}%` : '0%' }} // Moves left based on current slide
          transition={{ type: 'tween', duration: 0.5, ease: 'easeInOut' }} // Smooth animation
        >
          {children}
        </motion.div>
      </div>
      <div className="flex items-center justify-center gap-2 pt-8">
        {children.map((_, i) => (
          <button
            key={i}
            id={`bullet-${i}`}
            aria-label={`bullet-${i}`}
            className={`transition-all w-2 h-2 bg-aqua rounded-full slide-out cursor-pointer bg-white ${
              current === i ? 'p-0.5' : 'bg-opacity-50'
            }`}
            onClick={() => {
              setCurrent(i);
            }}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default CarouselNewDesign;
