import type { NextPageWithLayout } from '@pages/_app';
import type { ReactElement } from 'react';
import { Layout } from '@components/Layout';
import { Home } from '@components/Home';
import { GetStaticProps } from 'next';
import { HomeContext, HomeState } from '@components/Contexts/HomeContext';
import { FeedBlog } from 'models/Home.model';
import Header from '@components/_OldDesign/header';
import { FetchHighlightBlogs } from 'utils/fetchHighlightBlogs';

export const getStaticProps: GetStaticProps = async () => {
  try {
    const highlightBlogs: FeedBlog[] = await FetchHighlightBlogs();

    return {
      props: {
        highlightBlogs,
      },
    };
  } catch (error) {
    return {
      props: {
        highlightBlogs: [],
        data: JSON.stringify(error),
      },
    };
  }
};

const HomePage: NextPageWithLayout<HomeState> = (state) => {
  return (
    <>
      <Header
        content={{
          title: 'CODIUM - ผู้ให้บริการ Digital Native as a Service ชั้นนำ',
          content:
            'ผู้นำด้านโซลูชัน Digital Workflow บริการครบวงจร เช่น e-Memo, e-Tax และ e-Contract เพื่อขับเคลื่อนองค์กรของคุณสู่ยุคดิจิทัลอย่างมั่นใจ',
          image: null,
        }}
      />
      <HomeContext.Provider value={state}>
        <Home />
      </HomeContext.Provider>
    </>
  );
};

HomePage.getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>;
};

export default HomePage;
