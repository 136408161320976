import { Title } from '@components/Common';
import { useLocale } from '@components/Contexts/LocaleContext';
import Image from 'next/image';
import { ProductDetailProps } from './Service.model';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const ProductDetail: React.FC<ProductDetailProps> = ({ product, lang }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`flex-column items-center gap-6 md:${
        product.reverse ? 'flex-row-reverse' : 'flex-row'
      }`}
    >
      <div className="flex-1 flex-column gap-6">
        <div>
          <h3 className="text-h4MobileSemiBold md:text-h3MobileSemiBold mb-2">
            <span className="gradient-head-large">{product.name}</span>
          </h3>
          <motion.h4
            className="text-p2Medium lg:text-p1Medium overflow-hidden"
            initial={{ opacity: 0 }}
            animate={
              inView
                ? {
                    opacity: 1,
                    transition: { duration: 0.7 },
                  }
                : { opacity: 0 }
            }
          >
            {product.description[lang]}
          </motion.h4>
        </div>
        <p className="text-p2Regular">{product.detail[lang]}</p>
      </div>
      <div className="flex-1 lg:flex-none lg:w-[38%] flex justify-center items-center">
        <motion.div
          initial={{ opacity: 0, x: product.reverse ? -40 : 40 }}
          animate={
            inView
              ? {
                  opacity: 1,
                  x: 0,
                  transition: { duration: 0.7, delay: 0.3 },
                }
              : { opacity: 0, x: product.reverse ? -40 : 40 }
          }
        >
          <Image
            src={product.src}
            alt={product.name}
            width={423}
            height={211}
            objectFit={'contain'}
            loading="lazy"
          />
        </motion.div>
      </div>
    </div>
  );
};

const SoftwareSolutionService = () => {
  const { lang } = useLocale();

  const title = {
    en: (
      <>
        Tailored Software Solutions,{' '}
        <br className="hidden md:block lg:hidden" />
        Built for Your &apos;Business&apos;.
      </>
    ),
    th: (
      <>
        โซลูชันซอฟต์แวร์ที่ออกแบบเฉพาะ{' '}
        <br className="hidden md:block lg:hidden" />
        เพื่อธุรกิจของคุณ
      </>
    ),
  };

  const productDetail = [
    {
      src: '/images/new-homepage/e-memo.webp',
      name: 'e-Memo',
      description: {
        en: 'Electronic approval and workflow',
        th: 'ระบบจัดการเอกสารออนไลน์เเละลงลายมือชื่ออิเล็กทรอนิกส์ ',
      },
      detail: {
        en: 'Effortlessly and securely manage all your documents with ease. Our system is designed to adapt seamlessly to the complexity of your organization’s internal structure, fully supporting your paperless transformation. Experience the freedom of accessing and approving documents anytime, anywhere, with enhanced efficiency and convenience.',
        th: 'เซ็นอนุมัติเอกสารได้จากทุกที่ ทุกเวลา ครบทุกขั้นตอนงานเอกสารในที่เดียว สอดคล้องตามข้อกฎหมาย อำนวยความสะดวกพนักงานทุกคนแผนกในการทำงาน',
      },
      reverse: false,
    },
    {
      src: '/images/new-homepage/e-tax.webp',
      name: 'e-Tax',
      description: {
        en: 'All-in-one tax management system',
        th: 'ระบบออกใบกำกับภาษีรูปแบบอิเล็กทรอนิกส์',
      },
      detail: {
        en: 'An e-tax & e-receipt system designed for organizations: user-friendly, secure, and fully compliant with legal requirements. Effortlessly integrate via API for seamless connectivity with accounting systems and ERPs.',
        th: 'ระบบออกใบกำกับภาษีรูปแบบอิเล็กทรอนิกส์สำหรับองค์กร ใช้งานง่าย ปลอดภัย มาตราฐานตรงตามข้อกำหนดกฎหมาย แค่เชื่อม API ก็สามารถต่อกับระบบบัญชีและ ERP ทุกระบบ',
      },
      reverse: true,
    },
    {
      src: '/images/new-homepage/e-contract.webp',
      name: 'e-Contract',
      description: {
        en: 'Legally binding digital contracts',
        th: 'ระบบทำสัญญาดิจิทัล',
      },
      detail: {
        en: 'Embrace the future with our e-Contract platform—the first in Thailand to provide a legally-binding digital contract service. Streamline your workflow with e-Signatures, video identification, and legally recognized e-Timestamps. Say goodbye to the inefficiencies of traditional paperwork, save time, minimize errors, and take your business fully digital!',
        th: 'ก้าวสู่อนาคตด้วยแพลตฟอร์ม e-Contract ของเรา แพลตฟอร์มแรกในประเทศไทยที่ให้บริการสัญญาดิจิทัลที่มีผลผูกพันทางกฎหมาย ปรับปรุงกระบวนการทำงานของคุณให้มีประสิทธิภาพด้วย e-Signatures การยืนยันตัวตนผ่านวิดีโอ และ e-Timestamps ที่ได้รับการยอมรับทางกฎหมาย บอกลาความยุ่งยากของเอกสารแบบเดิม ประหยัดเวลา ลดความผิดพลาด และนำธุรกิจของคุณเข้าสู่ยุคดิจิทัลอย่างเต็มรูปแบบ!',
      },
      reverse: false,
    },
  ];

  return (
    <section aria-labelledby="software-solution-service">
      <div className="Container flex-column gap-10">
        <Title>{title[lang]}</Title>

        <div className="flex-column gap-20">
          {productDetail.map((product) => (
            <ProductDetail key={product.name} product={product} lang={lang} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SoftwareSolutionService;
