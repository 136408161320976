import { useLocale } from '@components/Contexts/LocaleContext';
import { useEffect, useState, useRef } from 'react';
import Image from 'next/image';
import { Title } from '@components/Common';
import AutoSlide from '@components/AutoSlide/AutoSlide';
import CarouselNewDesign from '@components/CarouselNewDesign/CarouselNewDesign';
import { BusinessOutcome } from './Service.model';

const imagePath = '/images/new-homepage/business-outcome-images/';

const businessOutcomes: BusinessOutcome[] = [
  {
    icon: '/icons/city.svg',
    name_en: 'Real Estate',
    name_th: 'อสังหาริมทรัพย์',
    image: imagePath + 'real-estate.webp',
  },
  {
    icon: '/icons/car.svg',
    name_en: 'Automotive',
    name_th: 'ยานยนต์',
    image: imagePath + 'automotive.webp',
    object_position: '50% 35%',
  },
  {
    icon: '/icons/magnet-energy.svg',
    name_en: 'Energy',
    name_th: 'พลังงาน',
    image: imagePath + 'energy.webp',
    object_position: '50% 35%',
  },
  {
    icon: '/icons/piggy-bank.svg',
    name_en: 'Finance',
    name_th: 'การเงิน',
    image: imagePath + 'finance.webp',
    object_position: '50% 40%',
  },
  {
    icon: '/icons/small-shop-alt.svg',
    name_en: 'Hospitality',
    name_th: 'โรงแรม ร้านอาหาร',
    image: imagePath + 'hospitality.webp',
  },
  {
    icon: '/icons/internet.svg',
    name_en: 'Technology',
    name_th: 'ไอที',
    image: imagePath + 'technology.webp',
  },
  {
    icon: '/icons/industry.svg',
    name_en: 'Manufacturing',
    name_th: 'อุตสาหกรรมการผลิต',
    image: imagePath + 'manufacturing.webp',
  },
  {
    icon: '/icons/pharmacy-cross-circle.svg',
    name_en: 'Medical & Health',
    name_th: 'การแพทย์และสุขภาพ',
    image: imagePath + 'medical-and-health.webp',
  },
  {
    icon: '/icons/bank.svg',
    name_en: 'Government',
    name_th: 'ภาครัฐ',
    image: imagePath + 'government.webp',
  },
  {
    icon: '/icons/plus.svg',
    name_en: 'Others',
    name_th: 'และธุรกิจอื่นๆ',
    image: imagePath + 'others.webp',
  },
];

const BusinessOutcomes = () => {
  const { lang } = useLocale();
  const containerRef = useRef<HTMLDivElement>(null);
  const autoSlideRef = useRef<HTMLDivElement>(null);
  const [autoSlideWidth, setAutoSlideWidth] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (autoSlideRef.current) {
      const width = autoSlideRef.current.getBoundingClientRect().width;
      setAutoSlideWidth(width);
    }
  }, [autoSlideRef]);

  const title =
    lang === 'en' ? (
      <>
        We&apos;re not just about <br className="md:hidden" />
        products—<br className="hidden md:block" />
        we&apos;re about delivering impactful <br className="hidden md:block" />
        business outcomes.
      </>
    ) : (
      'ออกแบบระบบให้เหมาะกับธุรกิจของคุณ'
    );

  const description =
    lang === 'en' ? (
      <>
        No matter your industry, we’re here to elevate your business to the
        digital age. <br className="md:hidden xxl:block" />
        Our solutions are tailored to meet the unique needs of each business,{' '}
        <br className="md:hidden xxl:block" />
        simplifying processes and enhancing security to fit your organization
        perfectly.
      </>
    ) : (
      <>
        ไม่ว่าคุณจะอยู่ในธุรกิจแบบใด เราพร้อมยกระดับการทำงานของคุณสู่ดิจิทัล{' '}
        <br />
        ด้วยระบบที่ออกแบบมาให้รองรับความต้องการเฉพาะของแต่ละอุตสาหกรรม{' '}
        <br className="md:hidden lg:block" />
        ช่วยให้คุณลดความยุ่งยาก
        และเพิ่มความปลอดภัยในการทำงานที่เหมาะกับธุรกิจของคุณ
      </>
    );

  return (
    <section aria-labelledby="business-outcomes">
      <div className="Container flex-column lg:flex-row justify-between items-center gap-10">
        {/* Left Section (Text & AutoSlide) */}
        <div
          ref={containerRef}
          className="w-full lg:w-[65%] flex-column gap-10"
        >
          <Title className="md:text-left">{title}</Title>
          <p className="p-text text-center md:text-left">{description}</p>

          {/* AutoSlide for mobile */}
          <div className="block md:hidden">
            <AutoSlide containerWidth={autoSlideWidth}>
              <div ref={autoSlideRef} className="flex">
                {businessOutcomes.map((item, index) => (
                  <div key={index} className="flex items-center gap-2 px-4">
                    <div className="w-10 h-10 flex justify-center items-center rounded-lg bg-[#FFFFFF33]">
                      <Image
                        src={item.icon}
                        alt={item.name_en}
                        width={24}
                        height={24}
                      />
                    </div>
                    <p className="whitespace-nowrap">
                      {lang === 'en' ? item.name_en : item.name_th}
                    </p>
                  </div>
                ))}
              </div>
            </AutoSlide>
          </div>

          {/* Grid view for desktop */}
          <div className="hidden md:grid grid-cols-2">
            {businessOutcomes.map((item, index) => (
              <div key={index} className="flex items-center gap-2 pr-8 py-4">
                <div className="w-10 h-10 flex justify-center items-center rounded-lg bg-[#FFFFFF33]">
                  <Image
                    src={item.icon}
                    alt={item.name_en}
                    width={24}
                    height={24}
                  />
                </div>
                <p className="whitespace-nowrap">
                  {lang === 'en' ? item.name_en : item.name_th}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Right Section (Carousel) */}
        <div className="relative lg:w-[30%]">
          <div className="lg:hidden">
            <CarouselNewDesign
              percent_width={100}
              autoSlide={true}
              height={350}
              currentSlide={(e) => setCurrentSlide(e)}
            >
              {businessOutcomes.map((item) => (
                <img
                  key={item.name_en}
                  src={item.image}
                  alt={item.name_en}
                  className="w-full h-[350px] object-cover flex-shrink-0"
                  style={
                    item.object_position
                      ? { objectPosition: item.object_position }
                      : {}
                  }
                />
              ))}
            </CarouselNewDesign>
          </div>
          <div className="hidden lg:block">
            <CarouselNewDesign
              percent_width={100}
              autoSlide={true}
              currentSlide={(e) => setCurrentSlide(e)}
            >
              {businessOutcomes.map((item) => (
                <img
                  key={item.name_en}
                  src={item.image}
                  alt={item.name_en}
                  style={{ width: '100%', objectFit: 'cover' }}
                />
              ))}
            </CarouselNewDesign>
          </div>

          {/* Overlay & Text on Current Slide */}
          <div className="absolute inset-0 flex items-end mb-10">
            <div
              className="absolute inset-0 rounded-[20px]"
              style={{
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0) 76.89%, #000000 96.41%)',
              }}
            ></div>
            <p className="relative m-5 px-2.5 border-l-4 border-red-500">
              {lang === 'en'
                ? businessOutcomes[currentSlide].name_en
                : businessOutcomes[currentSlide].name_th}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessOutcomes;
