import { CustomerProps } from '@pages/e-signature';
import { FeedBlog } from 'models/Home.model';
import { createContext, useContext } from 'react';

export interface HomeState {
  highlightBlogs: FeedBlog[];
}

export const HomeContext = createContext<HomeState>({
  highlightBlogs: [],
});

export const useHomeContext = () => {
  return useContext(HomeContext);
};
